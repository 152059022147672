<template>
  <aha-button
    class="spinner-wheel-spin-button"
    type="button"
    size="xs"
    @click="spin"
    :disabled="disabled"
  >
    <aha-icon
      name="play"
      viewBox="0 0 24 24"
      :width="iconSize"
      :height="iconSize"
    />
  </aha-button>
</template>

<script>
import util from '@/util'

export default {
  props: {
    iconSize: {
      type: Number,
      default: 15,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isStarted: false,
    }
  },
  mounted() {},
  methods: {
    spin(e) {
      this.$emit('spin', e)

      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Start Spin > Success',
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-wheel-spin-button {
  @apply min-w-0 p-0;
  border-radius: 100%;
}
</style>
