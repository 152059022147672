<template>
  <div
    class="spinner-wheel-winner-popup _container animate__bounceIn animate__animated"
    :style="{}"
    @click="closeWinnerPopup"
  >
    <div class="spinner-wheel-winner-popup _close-icon">
      <img src="@/assets/images/icon/close.svg" alt="close-winner" width="24">
    </div>
    <div class="spinner-wheel-winner-popup _winner-name">
      The results are in…
    </div>
    <spinner-wheel-label
      class="transform scale-150 _popup rounded-lg"
      :background-color="backgroundColor"
      :text="text"
      :variant="variant"
    />
  </div>
</template>
<script>

import Util from '@/util'
import SpinnerWheelLabel from './SpinnerWheelLabel.vue'
import { mapState } from 'vuex'

export default {
  components: {
    SpinnerWheelLabel,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      firework: null,
      timeoutClose: null
    }
  },
  computed: {
    ...mapState({
      variant: (state) => state.variant,
    }),
  },
  mounted() {
    this.firework = Util.firework()
    const labelEl = this.$el.querySelector('.spinner-wheel-label._container')
    if (labelEl) {
      const rgb = labelEl.style.backgroundColor // vue will convert whatever color into rgb ;)
      labelEl.style.boxShadow = `0px 14px 28px ${rgb.replace(')', ', 0.3)')}`
    }

    this.timeoutClose = setTimeout(() => {
      if (this.$store.state.showWinner) {
        this.closeWinnerPopup()
        this.timeoutClose = null
      }
    }, 5000)
  },
  destroyed() {
    if (this.timeoutClose) {
      clearTimeout(this.timeoutClose)
      this.timeoutClose = null
    }
    if (this.firework) this.firework.stopFirework()
  },
  methods: {
    closeShowWinner() {
      this.$store.state.showWinner = false
    },
    closeWinnerPopup() {
      if (this.closeShowWinner) this.closeShowWinner()
      if (this.firework) this.firework.stopFirework()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.spinner-wheel-winner-popup {
  &._container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: $highest-z-index - 2; // high z-index, but lower than firework's canvas
    @apply absolute top-0 right-0 h-full;
    @apply flex items-center justify-center flex-col;
  }

  &.--dark {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &._winner-name {
    font-size: 20px;
    line-height: 20px;
    @apply mb-6 font-bold;
  }

  &._close-icon {
    @apply cursor-pointer absolute right-4 top-4 text-base-70;
    @apply p-2 hover:text-primary-90;
  }

  @media (max-width: 900px) {
    &._container {
      display: block;
      padding: 10px;
    }
    .spinner-wheel-label._container {
      &._popup {
        min-width: auto;
        width: auto;
        margin: 0 auto;
        max-width: 500px;
        transform: scale(1);
      }
    }

    .spinner-wheel-winner-popup._winner-name {
      text-align: center;
      margin-top: 225px;
    }

    ._close-icon {
      top: 10px;
      right: 10px;
    }
  }

  @media (max-width: 480px) {
    .spinner-wheel-winner-popup._winner-name {
      text-align: center;
      margin-top: 150px;
    }
  }
}
</style>
