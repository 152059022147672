<template>
  <div class="entries-box vertical layout entries">
    <div class="entries-box __blur"></div>
    <div class="entries-box __header horizontal layout center">
      <div class="flex entries-count">
        Entries
        <span>{{ entries.length | formatTotalPoint }}</span>
      </div>
    </div>
    <form
      class="entries-box __input-entry horizontal layout center"
      @submit="submitNewEntry"
    >
      <div class="relative flex mr-2">
        <aha-input
          type="text"
          placeholder="entry1, entry2, ..."
          v-model="newEntry"
          :classes="variant === 'white' ? 'light' : 'dark'"
          :maxLength="limitEntryLength"
          class="w-full"
          @keyup="onKeyUpEntryInput"
        >
        </aha-input>
      </div>
      <aha-button
        type="submit"
        :disabled="newEntry.length > limitEntryLength"
      >
        <span>Add</span>
      </aha-button>
    </form>
    <div class="flex entries-box __list">
      <p class="text-center text-base-60" v-if="!entries.length">
        Enter entries separated by a comma
      </p>
      <ul class="w-full">
        <li v-for="(item, index) in entries" :key="index" class="w-full">
          {{ item.name }}
          <img src="@/assets/images/icon/trash-alt-solid.svg" alt="remove entry" class="remove-entry" @click="clickRemoveEntry(index)" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import util from '@/util'

export default {
  components: {
  },
  data() {
    return {
      newEntry: '',
      limitEntryLength: 2500,
    }
  },
  computed: {
    ...mapState({
      variant: (state) => state.variant,
      entries: (state) => state.entries,
      winner: (state) => state.winner,
      wheelSpinning: (state) => state.wheelSpinning,
    }),
  },
  destroyed() {
  },
  methods: {
    async submitNewEntry(event) {
      event.preventDefault()
      if (!this.newEntry || this.wheelSpinning) return
      if (this.newEntry && !this.newEntry.trim()) return
      let entries = this.newEntry.split(',')      
      entries = entries.map((item) => {
        return {
          name: item,
          id: util.makeid()
        }
      })
      this.$store.state.entries = this.$store.state.entries.concat(entries)
      this.newEntry = ''
      if (entries.length > 1) {
        util.gaTrackEvent(
          'Spinner Wheel Page',
          'Spinner Wheel > Entries list > User add multiple entry',
        )
      } else {
        util.gaTrackEvent(
          'Spinner Wheel Page',
          'Spinner Wheel > Entries list > User add entry',
        )
      }
    },

    async clickRemoveEntry(index) {
      if (this.wheelSpinning) return
      this.$store.state.winner = null
      this.$store.state.currentText = ''
      this.$store.state.currentStyle = ''
      this.$store.state.entries.splice(index, 1)

      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Entries list > User remove entry',
      )
    },
    onKeyUpEntryInput() {
      if (this.newEntry.length > this.limitEntryLength) {
        this.newEntry = this.newEntry.substr(0, this.limitEntryLength)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

.entries {
  @apply pr-3;
}

.entries-box {
  
  &.__header {
    padding: 12px 0;
    font-weight: bold;
    @apply text-base-80;

    &.black {
      color: #fff;
    }
    .entries-count {
      font-size: 14px;
      span {
        font-weight: normal;
        background: rgba(175, 175, 175, 0.3);
        border-radius: 4px;
        padding: 2px 6px;
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }

  .pending-count {
    margin-right: 10px;
    font-size: 12px;
    font-weight: normal;

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: block;
      background-color: #AFAFAF;
      margin-right: 2px;
    }

    &.new-audience {
      span {
        background-color: #33C173;
      }
    }
  }

  &.__blur {
    position: absolute;
    height: 300px;
    width: 280px;
    top: 60px;
    left: 14px;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: -1;
  }

  & /deep/ input {
      padding: 4px 8px;
      border-radius: 6px;
      font-size: 16px;
      width: 100%;
      padding-right: 30px;
      height: 2rem;
      background-color: transparent;

      @media screen and (prefers-color-scheme: dark) {
        background-color: transparent;
      }
      &.dark {
        @apply text-secondary-20 bg-transparent border border-secondary-20;
        @apply hover:border-primary-90 focus:border-primary-90;
      }

      &::placeholder {
        font-size: 14px;
      }
    }

  & /deep/ .aha-input--char-counter {
    height: 2rem;
    font-size: 12px;
  }

  &.__input-entry {
    margin-bottom: 16px;

    .count {
      position: absolute;
      right: 7px;
      font-size: 14px;
      opacity: 0.6;
      top: 8px;
    }

    > .aha-button {
      margin-top: -5px;
      padding: 4px 0px;
      font-size: 14px;
      min-width: 3rem;
      & /deep/ .aha-spinner {
        margin-bottom: -5px;
      }
    }
  }

  &.__reset {
    font-size: 12px;
    .btn-update-wheel {
      @apply text-primary-90 font-bold;
      cursor: pointer;
      margin-left: 2px;

      &:hover {
        @apply text-primary-100;
      }
    }
  }

  &.__list {
    overflow-y: auto;
    max-width: 100%;
    max-height: 200px;

    p {
      margin-top: 20px;
      font-size: 14px;
    }

    ul {
      li {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 4px;
        position: relative;

        &:nth-child(2n + 1) {
          background: rgba(211, 215, 225, 0.3);
        }

        .remove-entry {
          @apply text-base-60;
          position: absolute;
          right: 10px;
          top: 10px;
          display: none;
          cursor: pointer;
          width: 12px;
          height: auto;
        }

        &:hover {
          .remove-entry {
            display: block;
            &:hover {
              opacity: 0.6;
            }
          }
        }

        @media (max-width: 900px) {
          .remove-entry {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .entries {
    @apply pr-0;
  }
}
</style>
