export default {
  colors: [
    // https://trello.com/c/383hc2zf/430-%C4%91%E1%BB%95i-list-m%C3%A0u-d%C3%B9ng-cho-c%C3%A1c-chart
    'rgba(78, 170, 241, 1)', // blue
    'rgba(255, 175, 9, 1)', // yel
    'rgba(40, 194, 112, 1)', // green
    'rgba(253, 93, 161, 1)', // pink
    'rgba(118, 105, 191, 1)', // purple
    'rgba(18, 67, 69, 1)', // dark teal
    'rgba(156, 162, 174, 1)', // grey
    'rgba(79, 151, 241, 1)', // blue 2
    'rgba(255, 199, 0, 1)', // yel 2
    'rgba(40, 194, 129, 1)', // green 2
    'rgba(253, 93, 142, 1)', // pink 2
    'rgba(127, 105, 191, 1)', // purple 2
    'rgba(37, 43, 53, 1)', // near black
    'rgba(156, 165, 174, 1)', // grey 2
    'rgba(77, 183, 240, 1)', // blue 3
    'rgba(255, 156, 16, 1)', // orange
    'rgba(40, 194, 99, 1)', // green 3
    'rgba(253, 92, 173, 1)', // pink 3
    'rgba(110, 105, 191, 1)', // purple 3
    'rgba(59, 30, 54, 1)', // dark plum
    'rgba(162, 155, 175, 1)', // grey purple
    'rgba(116, 76, 242, 1)', // purple 4
    'rgba(154, 240, 37, 1)', // neon green
    'rgba(43, 158, 195, 1)', // sky
    'rgba(253, 152, 89, 1)', // light orange
    'rgba(182, 105, 191, 1)', // weird purple
    'rgba(55, 52, 33, 1)', // some brown-green
    'rgba(155, 170, 174, 1)', // grey blue
    'rgba(74, 225, 239, 1)', // fresh blue
    'rgba(255, 100, 28, 1)', // fresh orange
  ],
  colorsHex: ['4EAAF1', '#FFAF09', '#28C270', '#FF5DA1', '#7669BF', '#124345'],
  defaultBaseColour: [
    {
      color: '#ffffff',
      textColour: '#313131',
    },
    {
      color: '#c9e6fd',
      textColour: '#313131',
    },
    {
      color: '#fff4da',
      textColour: '#313131',
    },
    {
      color: '#FFEBF8',
      textColour: '#313131',
    },
    {
      color: '#222222',
      textColour: '#ffffff',
    },
    {
      color: '#212a4c',
      textColour: '#ffffff',
    },
    {
      color: '#541330',
      textColour: '#ffffff',
    },
  ],
  lighterColors: [
    '#c7e2f4',
    '#fce4b6',
    '#bee9cd',
    '#fbcadc',
    '#d3cfe5', // purple 1st
    '#b6c3c1',
    '#dedfe0', // 1st gray
    '#c7dcf4',
    '#fcebb6', // 2nd yellow
    '#bee9d3',
    '#fbcbd7', // 2nd pink
    '#d5cfe5', // 2nd purple
    '#bbbcbc',
    '#dee0e0', // 2nd gray
    '#c7e6f4', // 3rd blue
    '#fcddb7',
    '#bee9ca',
    '#fbcae0', // 3rd pink
    '#d1cfe5', // 3rd purple
    '#c1b8bc',
    '#e0dde0',
    '#d2c7f4',
    '#ddf7bb', // yellow green
    '#bedee7',
    '#fbdcc8',
    '#e6cee5', // last purple
    '#c0beb6',
    '#dee1e0',
    '#c7f2f3',
    '#fccdb7',
  ],

  entriesDefault: [
    {
      name: 'Entry 1',
      id: 1
    },
    {
      name: 'Entry 2',
      id: 2
    },
    {
      name: 'Entry 3',
      id: 3
    },
    {
      name: 'Entry 4',
      id: 4
    },
    {
      name: 'Entry 5',
      id: 5
    },
    {
      name: 'Entry 6',
      id: 6
    },
    {
      name: 'Entry 7',
      id: 7
    },
    {
      name: 'Entry 8',
      id: 8
    },
  ]
}
