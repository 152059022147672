<template>
  <div class="wheel-canvas">
    <img src="@/assets/images/icon/pointer.svg" width="70" class="wheel-pointer " />
    <div class="absolute spin-btn-wrap">
      <spinner-wheel-spin-button
        class="spin-btn"
        @spin="startSpin(false)"
        :icon-size="38"
        :disabled="wheelSpinning"
      />
    </div>
    <canvas id="canvas" width="465" height="465">
      <p class="text-red-500" align="center">
        Sorry, your browser doesn't support canvas. Please try another.
      </p>
    </canvas>
    <div class="wheel-canvas__shadow"></div>
  </div>
</template>

<script>
import util from '@/util'
// import gaCategories from '@/constant/gaCategories'
import Winwheel from '@/assets/js/WinWheel'
import SpinnerWheelSpinButton from './SpinnerWheelSpinButton.vue'
import { mapState } from 'vuex'
import constant from '@/constant'

const tick = require('@/assets/audio/spinner-wheel-tick.mp3')
const celeb = require('@/assets/audio/spinner-wheel-celebration.mp3')

const MAX_SLICE = 60
const MAX_LABEL_TEXT_LEN = 121
const MAX_FONT_SIZE = 26
const FIXED_COLORS = {
  1: ['#BC2828'],
  2: ['#D3362A', '#199BD3'],
  3: ['#115FE3', '#D3362A', '#FED103'],
  4: ['#8C01FA', '#FB6D02', '#FEE603', '#62C52F'],
  5: ['#8C01FA', '#D50156', '#FC8E02', '#62C52F', '#115FE3'],
  6: ['#D50156', '#ED4F24', '#FDC702', '#62C52F', '#1581DA', '#5D0AF9'],
  7: ['#C4017D', '#ED4F24', '#FDBA02', '#CFDF02', '#62C52F', '#0A2FF1', '#7E04FA'],
  8: ['#8D01F8', '#CA016F', '#FB3501', '#FC9E02', '#FEE503', '#62C52F', '#0760ED', '#2C13F8'],
  9: ['#D10160', '#FB2D01', '#FF6700', '#FED103', '#B0DA02', '#31AF64', '#0F57E5', '#460EF8', '#9001F1'],
  10: ['#D3015A', '#FB2C01', '#FC7C02', '#FED103', '#CFDF02', '#68C822', '#22A0BE', '#0719F7', '#5D0AF9', '#9701E0'],
  11: ['#D60153', '#FB2B01', '#FC7F02', '#FDC402', '#F0E503', '#77D100', '#39AD8C', '#105DE4', '#2A13F8', '#6E07F9', '#9B01D7'],
  12: ['#A701BD', '#DB0149', '#FA2601', '#FB6A02', '#FCAB02', '#FEDD03', '#B6DB02', '#61C531', '#25A2B7', '#0E4BE9', '#3B10F8', '#8203FA'],
}

export default {
  components: {
    SpinnerWheelSpinButton,
  },
  data() {
    return {
      prevEntries: null,
      theWheel: null,
      wheelPower: 2,
      audioTick: new Audio(tick),
      audioCeleb: new Audio(celeb),
      lastPlay: Date.now(),
      winnerIndex: -1,
    }
  },
  computed: {
    ...mapState({
      entries: (state) => state.entries,
      wheelSpinning: (state) => state.wheelSpinning,
      winner: (state) => state.winner,
    }),
  },
  watch: {
    entries: function watchEntriesToUpdateWheel(newEntries) {
      if (JSON.stringify(newEntries) !== this.prevEntries) {
        this.initWheel()
        this.prevEntries = JSON.stringify(newEntries)
      }
    },
  },
  mounted() {
    this.initWheel()
  },
  destroyed() {
    this.clearWheel()
  },
  methods: {
    initWheel() {
      this.startSpin(true)
    },
    clearWheel() {
      if (this.theWheel != null) {
        this.theWheel.stopAnimation(false)
        this.$store.state.wheelSpinning = false
        this.theWheel.clearCanvas()
      }
    },
    drawWheel(auto, rotationAngle = null) {
      let data = this.entries.map((item) => {
        return item.name
      })

      if (!this.entries.length) {
        data = constant.entriesDefault.map((item) => {
          return item.name
        })
      }
      const textFontSize = MAX_FONT_SIZE - Math.floor(data.length / 4)
      const textMaxLength = MAX_LABEL_TEXT_LEN - textFontSize * 1.125

      let segments
      if (data.length > MAX_SLICE) {
        segments = Array(MAX_SLICE).fill(0).map((_, i) => {
          const hue = (i / MAX_SLICE) * 360
          return {
            fillStyle: `hsla(${hue}, 80%, 50%, 1)`,
            strokeStyle: `hsla(${hue}, 80%, 50%, 1)`,
            index: i,
          }
        })
      } else {
        segments = data.map((d, i) => {
          const hue = (i / data.length) * 360
          if (FIXED_COLORS[data.length]) {
            return {
              fillStyle: FIXED_COLORS[data.length][i],
              text: this.cutText(d, textMaxLength),
              fullText: d,
            }
          }
          return {
            fillStyle: `hsla(${hue}, 80%, 50%, 1)`,
            text: this.cutText(d, textMaxLength),
            fullText: d,
          }
        })
      }

      let startAngle = 0
      let stopAngle = null
      const pointerAngle = 309
      const segmentAngle = 360 / segments.length
      if (this.winner != null) {
        this.winnerIndex = this.findIndexByWinner(this.winner)
      }

      if (this.winnerIndex > -1) {
        const currentCount = this.winnerIndex % segments.length
        stopAngle = segmentAngle * currentCount + segmentAngle / 2
      } else {
        const currentCount = Math.floor(Math.random() * segments.length)
        stopAngle = segmentAngle * currentCount + segmentAngle / 2
      }

      let animation = {
        type: 'spinToStop',
        duration: 10,
        spins: 7,
        easing: 'Power1.easeOut',
        startAngle,
        stopAngle,
        callbackFinished: this.stopSpin,
        callbackAfter: this.showSegment,
        callbackSound: this.playSound,
        soundTrigger: 'segment',
      }
      if (auto) {
        animation = {
          type: 'spinOngoing',
          duration: 1000,
          spins: 10,
          easing: 'easeNone',
        }
      }

      // Create new wheel object specifying the parameters at creation time.
      return new Winwheel({
        pointerAngle,
        rotationAngle,
        textFillStyle: 'white',
        outerRadius: 232,
        innerRadius: 35,
        textFontSize,
        textFontFamily: 'Nunito, sans-serif',
        textFontWeight: 'bold',
        textOrientation: 'horizontal',
        textAlignment: 'outer',
        textMargin: 17,
        textDirection: 'reversed',
        numSegments: segments.length,
        segments,
        animation,
      })
    },

    findIndexByWinner(winner) {
      return this.entries
        .findIndex((d) => d.id === winner.id)
    },

    // This function is called when the sound is to be played.
    playSound() {
      const now = Date.now()
      if (now - this.lastPlay < 45) {
        return
      }
      this.lastPlay = now

      // Play the sound.
      this.audioTick.pause()
      this.audioTick.currentTime = 0
      this.audioTick.play()
    },

    startSpin(auto) {
      if (auto) { // Case 1: Auto mode, the first time
        this.startAutoSpin()
      } else if (this.entries && this.entries.length) { // Case 2: Spin with real data
        this.startRealSpin(true)
      } else { // Case 3: Spin with default entries
        this.startRealSpin(false)
      }
    },

    startAutoSpin() {
      this.drawAndSpin(true)
      this.$store.state.wheelSpinning = false
    },

    async startRealSpin(realData) {
      // Only start spinning on auto mode or the wheel is not spinning already
      if (!this.wheelSpinning) {
        try {
          this.$store.state.wheelSpinning = true
          this.$store.state.winner = this.entries[util.randomIntFromInterval(0, this.entries.length - 1)]
          if (realData) {
            // Make it spin first, then call for API
            this.drawAndSpin(true)
          }

          // util.gaTrackEvent(
          //   gaCategories.PRESENTATION_EDITOR,
          //   'Spinner wheel > Start spin > Success',
          // )
        } catch (e) {
          // util.gaTrackEvent(
          //   gaCategories.PRESENTATION_EDITOR,
          //   'Spinner wheel > Start spin > Failure',
          // )
        }
        this.drawAndSpin(false)
      }
    },

    drawAndSpin(auto) {
      let currentAngle = null
      if (this.theWheel != null) {
        currentAngle = this.theWheel.getRotationPosition()
      }
      this.clearWheel()
      this.$store.state.wheelSpinning = true
      this.theWheel = this.drawWheel(auto, currentAngle)
      this.theWheel.startAnimation()
    },

    async stopSpin() {
      this.$store.state.wheelSpinning = false
      setTimeout(() => {
        this.audioCeleb.play()
      }, 1e3)
      this.theWheel.stopAnimation(false)
      this.$store.state.showWinner = true
    },

    showSegment(segment) {
      if (this.entries && this.entries.length > MAX_SLICE) {
        const segmentIndex = segment.index +
            Math.floor(this.winnerIndex / MAX_SLICE) * MAX_SLICE
        const s = this.entries[segmentIndex % this.entries.length]
        if (s) {
          this.selectSegment(segment.fillStyle, s.name)
        }
      } else {
        this.selectSegment(segment.fillStyle, segment.fullText)
      }
    },

    selectSegment(style, text) {
      this.$store.state.currentStyle = style
      this.$store.state.currentText = text
    },

    cutText(str, width) {
      const oldStr = str
      const span = document.createElement('span')
      span.style.display = 'inline'
      span.style.visibility = 'hidden'
      span.style.padding = '0px'
      document.body.appendChild(span)

      span.innerHTML = str
      if (span.offsetWidth > width) {
        let posStart = 0
        let posEnd = str.length
        let posMid
        let posLength
        // eslint-disable-next-line
        while (posLength = (posEnd - posStart) >> 1) {
          posMid = posStart + posLength
          span.innerHTML = `${str.substring(0, posMid)}...`

          if (span.offsetWidth > width) {
            posEnd = posMid
          } else {
            posStart = posMid
          }
        }

        str = str.substring(0, posStart)
      }
      document.body.removeChild(span)
      return oldStr !== str ? `${str}...` : str
    },
  },
}
</script>

<style lang="scss" scoped>
.wheel-canvas {
  @apply flex items-center justify-center relative right-0 top-6;
}

$wheel-shadow-offset: 1.5px;

.wheel-canvas__shadow {
  @apply absolute rounded-full z-10;
  width: calc(100% - #{$wheel-shadow-offset});
  height: calc(100% - #{$wheel-shadow-offset});
  box-shadow: inset 0 7px 0 1.5px rgba(0, 0, 0, 0.15);
}

.spin-btn-wrap {
  @apply w-20 h-20 flex items-center justify-center;
  border-radius: 100%;
  background: #FFFFFF;
  border: 3.2px solid #EFF1F7;
  box-shadow: 0px 7.15653px 0px 1.78913px rgba(0, 0, 0, 0.13);
}

.spin-btn {
  @apply w-15 z-20;
  height: 58px;
  margin-top: -4px;
  &:active {
    height: 60px;
    margin-top: -5px;
  }
}

.wheel-pointer {
  @apply absolute z-20 inset-x-2;
  top: 50px;
  bottom: 50px;
}
</style>
