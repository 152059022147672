<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              Share AhaSlides Spinner Wheel with your friends!
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="copy-box">
                <input type="text" readonly value="https://ahaslides.com/features/spinner-wheel/?utm_source=tool_share&utm_medium=share&utm_campaign=spinner_wheel_share" class="input-copy" id="input-copy">
                <aha-button @click="copyInput()" v-if="!copied" class="button-copy">Copy</aha-button>
                <aha-button @click="copyInput()" class="copied button-copy" v-if="copied">Copied</aha-button>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('close')">
                ✕
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import util from '@/util'

export default {
  data() {
    return {
      copied: false
    }
  },
  methods: {
    copyInput() {
      const textBox = document.getElementById("input-copy")
      textBox.select()
      document.execCommand("copy")
      this.copied = true
      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Copy link share',
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

.input-copy {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px 16px;
  padding-right: 92px;
  font-size: 14px;
  outline: none;
  background-color: #fff;
}
.copy-box {
  position: relative;

  .button-copy {
    position: absolute;
    right: 16px;
    top: 7px;
    font-size: 14px;
    height: 28px;
  }
}
.modal-default-button {
  position: absolute;
  top: 0;
  right: 8px;
  padding: 8px;
  font-size: 20px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: table;
  // transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 0 16px;
}

.modal-container {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  // transition: all .3s ease;
  position: relative;
}

.modal-header  {
  font-size: 14px;
  font-weight: bold;
}

.modal-body {
  margin: 30px 0;
}

.modal-default-button {
  float: right;
}
@media (max-width: 900px) {
  .modal-wrapper {
    position: absolute;
    top: 330px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .modal-wrapper {
    top: 230px;
  }
}
</style>