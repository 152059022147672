import Vue from 'vue'
import Vuex from 'vuex'
import util from '@/util'

// link ?entries=Entry 1|Entry 2|Entry 3|Entry 4|Entry 5|Entry 6|Entry 7|Entry 8

const entries = util.getParamValue('entries')
let defaultEntries = []
if (entries) {
  try {
    defaultEntries = entries.split('|').map((item, key ) => {
      return {
        id: key + 1,
        name: item
      }
    })
  } catch {
    // do not thing
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    entries: defaultEntries,
    variant: 'white',
    wheelSpinning: false,
    winner: null,
    currentStyle: '',
    currentText: '',
    showWinner: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
