import confetti from 'canvas-confetti'
import Constant from './constant'

const formatNumber = (number) => {
  if (!number) return 0
  return parseFloat(number).toLocaleString('en')
}

const randomIntFromInterval = (min, max) => { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const makeid = (length = 5) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

const initConfettiCanvas = () => {
  const canvas = document.getElementById('firework-canvas')
  if (!canvas) return null
  canvas.confetti =
    canvas.confetti ||
    confetti.create(canvas, {
      resize: true,
    })
  return canvas
}

const firework = function() {
  const self = this
  const canvas = initConfettiCanvas()
  const colors = Constant.colorsHex.filter((item, key) => {
    return item && key < 7
  })

  // eslint-disable-next-line func-names
  const _startFirework = function () {
    let start = null
    let count = 0
    let timeFirework = 250
    // eslint-disable-next-line
    function step(timestamp) {
      if (count === 3) {
        timeFirework = 800
      }
      if (!start) start = timestamp
      const progress = timestamp - start
      if (progress > timeFirework) {
        start = null
        count++
        canvas.confetti({
          startVelocity: 5,
          spread: 360,
          ticks: 200,
          decay: 0.98,
          particleCount: 80,
          shapes: ['circle'],
          colors,
          zIndex: 100,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.3,
          },
        })
      }
      self.myReq = window.requestAnimationFrame(step)
    }
    self.myReq = window.requestAnimationFrame(step)
  }
  // eslint-disable-next-line func-names
  self.stopFirework = function () {
    if (self.myReq) {
      window.cancelAnimationFrame(self.myReq)
      self.myReq = undefined
    }
  }
  _startFirework(false)
  // eslint-disable-next-line consistent-return
  return self
}

const getParamValue = (param, url) => {
  if (!url) url = window.location.href
  param = param.replace(/[\\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const gaTrackEvent = (eventCategory, eventAction, eventLabel) => {
  eventCategory = eventCategory || ''
  eventAction = eventAction || ''
  eventLabel = eventLabel || ''
  if (window.ga) {
    window.ga('send', 'event', eventCategory, eventAction, eventLabel)
  }
}

export default {
  formatNumber,
  randomIntFromInterval,
  makeid,
  firework,
  initConfettiCanvas,
  getParamValue,
  gaTrackEvent,
}