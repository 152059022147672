<template>
  <div
    class="spinner-wheel-label _container"
    :style="{'background-color': backgroundColor}"
    :class="[
      `--${variant}`,
      {
        '--initial': !text,
        '--winning': showWinner,
      },
    ]"
  >
    <div class="spinner-wheel-label _prompt" v-if="!text">
      Press ▶ to spin the wheel
    </div>
    <div
      class="spinner-wheel-label _text"
      v-else
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    showWinner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      variant: (state) => state.variant,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.spinner-wheel-label {
  &._container {
    font-size: 20px;
    max-width: 312px;
    @apply rounded-lg text-center w-full font-bold py-2;

    &._popup {
      min-width: 312px;
      max-width: 700px;
      width: unset;
    }
  }

  &._text {
    @apply px-4 text-base-0 truncate;
  }

  &.--white.--initial {
    @apply bg-base-0 text-secondary-40 border-secondary-15;
    border-width: 1.5px;
  }

  &.--dark {
    @apply text-base-60;
    background-color: rgba(10, 10, 10, 0.4);
  }

  &.--winning {
    transition: all 0.3s ease-in-out;
    transform: translate(244px, 203px) scale(1.5);
    opacity: 0;
  }
}

@media (max-width: 480px) {
  .spinner-wheel-label._container {
    @apply text-base py-1;
  }
}
</style>
