<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              Are you sure you want to clear all entries?
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <aha-button @click="resetData()" class="ok">
                OK
              </aha-button>
              <button class="modal-default-button close" @click="$emit('close')">
                ✕
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import util from '@/util'

export default {
  data() {
    return {
    }
  },
  methods: {
    resetData() {
      this.$store.state.entries = []
      this.$emit('close')
      this.$store.state.currentStyle = ''
      this.$store.state.currentText = ''
      this.$store.state.showWinner = false
      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > New data',
      )
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 0 16px;
}

.modal-container {
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  position: relative;
}

.modal-header {
  font-size: 14px;
  font-weight: bold;
}

.modal-body {
  margin: 20px 0;
}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-footer {
  margin-top: 16px;
  text-align: center;
  .ok {
    height: 25px;
    padding: 1rem 0;
    min-width: 50px;
  }
}
.modal-default-button {

  &.close {
    position: absolute;
    top: 0;
    right: 8px;
    padding: 8px;
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .modal-wrapper {
    position: absolute;
    top: 330px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .modal-wrapper {
    top: 230px;
  }
}
</style>