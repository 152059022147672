<template>
  <div id="aha-spinner-wheel">
    <canvas id="firework-canvas" />

    <div class="nav-action">
      <ul v-if="showAction" class="spinner-wheel-action">
        <li class="nav-item">
          <a class="nav-link" @click="openConfirmNewData()">New</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="save()">Save</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="openShare()">Share</a>
        </li>
      </ul>
    </div>

    <div class="relative h-full">
      <div class="h-full spinner-wheel-table __container horizontal layout">
        <aha-svg
          v-if="variant === 'white'"
          maintain-color
          class="spinner-wheel-table __wrapper"
          name="spinner-wheel-bg-white"
          viewBox="0 0 502 322"
          width="800"
          height="512"
        />

        <aha-svg
          v-else
          maintain-color
          class="spinner-wheel-table __wrapper"
          name="spinner-wheel-bg-dark"
          viewBox="0 0 502 322"
          width="800"
          height="512"
        />

        <div class="spinner-wheel-table __entries">
        </div>
        <div class="spinner-wheel-table __wheel vertical layout">
          <img src="@/assets/images/bg-spinner-wheel.svg" alt="spinner wheel" class="bg-spinner-wheel">
          <spinner-wheel />
        </div>
        <div class="spinner-wheel-table __entries-box vertical layout">
          <div class="spinner-wheel-table __label">
            <spinner-wheel-label 
              :show-winner="showWinner"
              :background-color="currentStyle"
              :text="currentText"
              :variant="variant"
            />
          </div>
          <SpinnerWheelEntries
            class="flex"
          />
        </div>
        <spinner-wheel-winner-popup
          v-if="showWinner"
          :background-color="currentStyle"
          :text="currentText"
          :variant="variant"
        />
      </div>
    </div>
    <Share v-if="showModalShare" @close="showModalShare = false" />
    <ConfirmNewData v-if="showConfirmNewData" @close="showConfirmNewData = false" />
  </div>
</template>

<script>
import SpinnerWheel from '@/components/SpinnerWheel.vue'
import SpinnerWheelEntries from '@/components/SpinnerWheelEntries.vue'
import SpinnerWheelLabel from '@/components/SpinnerWheelLabel.vue'
import SpinnerWheelWinnerPopup from '@/components/SpinnerWheelWinnerPopup.vue'
import Share from '@/components/Share.vue'
import ConfirmNewData from '@/components/ConfirmNewData.vue'
import VueCookie from 'vue-cookie'

import { mapState } from 'vuex'
import util from '@/util'
import axios from 'axios'

export default {
  components: {
    SpinnerWheel,
    SpinnerWheelEntries,
    SpinnerWheelLabel,
    SpinnerWheelWinnerPopup,
    Share,
    ConfirmNewData,
  },
  data() {
    return {
      showAction: false,
      showModalShare: false,
      showConfirmNewData: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      variant: (state) => state.variant,
      showWinner: (state) => state.showWinner,
      currentStyle: (state) => state.currentStyle,
      currentText: (state) => state.currentText
    }),
  },
  created() {
    this.showAction = util.getParamValue('action')
  },
  methods: {
    openConfirmNewData() {
      this.showConfirmNewData = true
      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Open Confirm New Data',
      )
    },
    async save() {
      if (this.isLoading) return
      const data = JSON.stringify(this.$store.state.entries)
      const token = VueCookie.get('ahaToken')
      if (!token) {
        window.parent.postMessage({
          'func': 'parentFunc',
          'message': 'save spinner wheel',
          'url': `/save-presentation/?spinnerwheel=true&wheel-data=${data}`
        }, "*");
      } else {
        const spinnerWheelEntries = this.$store.state.entries.map((item) => item.name)
        this.isLoading = true
        await axios.post('https://presenter.ahaslides.com/api/presentation/create/', {
          name: 'Your saved Spinner Wheel',
          hasDefaultSlide: false,
          spinnerWheelEntries,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        window.parent.postMessage({
          'func': 'parentFunc',
          'message': 'save spinner wheel',
          'url': '/apps/presentations'
        }, "*");
        this.isLoading = false
      }
      
      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Save data',
      )
    },
    openShare() {
      this.showModalShare = true
      util.gaTrackEvent(
        'Spinner Wheel Page',
        'Spinner Wheel > Open share',
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.nav-action {
  margin: 0 16px;
}

.spinner-wheel-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 280px;
  color: #fff;
  background: #348EF6;
  border-radius: 22px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 16px;

  @media (max-width: 480px) {
    width: 250px;
    font-size: 12px;
    margin-bottom: 12px;
  }

  .nav-item {
    cursor: pointer;
    position: relative;
    flex: 1;
    color: white;
    font-weight: bold;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-item:not(:last-child) .nav-link {
    &:after {
      content: "";
      opacity: 0.3;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      position: absolute;
      display: inline-block;
      background: #D3D7E1;
    }
  }
}

#aha-spinner-wheel {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 10px;
  max-width: 800px;
  position: relative;
  width: 100%;
  height: 515px;

  .spinner-wheel-table {
    &.__wrapper {
      @apply absolute;
    }

    &.__entries {
      width: $spinner-wheel-left-part-width;
    }

    &.__entries-box {
      height: 358px;
      position: absolute;
      top: 70px;
      padding-left: 28px;
      padding-right: 10px;
      padding-bottom: 15px;
      width: 320px;
    }

    &.__container {
      max-width: 800px;
      margin: 0 auto;
      width: 100%;
    }

    &.__label {
      @apply flex items-center justify-center;
      height: 70px;
      width: $spinner-wheel-left-part-width;
      margin-left: -16px;
      margin-top: 2px;
    }

    &/deep/ audio {
      border: 2px solid #ccc;
      border-radius: 30px;
      height: 32px;
      position: absolute;
      top: -50px;
      width: 288px;
      left: 33px;
    }

    .hide-audio {
      visibility: hidden;
    }
  }

  canvas#firework-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $highest-z-index - 1;
    pointer-events: none;
  }

  .bg-spinner-wheel {
    display: none;
  }

  @media (max-width: 900px) {
    height: auto;
    .spinner-wheel-table {    
      &.layout.horizontal.__container {
        display: block;
      }

      &.__entries-box {
        position: relative;
        margin: 0 auto;
        padding: 0;
        max-width: 465px;
        width: 100%;
        padding-bottom: 20px;
        padding: 0 10px;
        top: 110px;
      }

      .entries-box.__list {
        max-width: 100%;
        margin-bottom: 20px;
      }

      &.__wheel {
        width: 465px;
        margin: 0 auto;
        position: relative;
        top: 65px;
      }

      &.__wrapper {
        display: none;
      }

      &.__label {
        margin: 0;
        width: auto;
        position: absolute;
        top: -585px;;
        left: 0;
        width: 100%;
      }
    }

    .bg-spinner-wheel {
      position: absolute;
      bottom: -24px;
      transform: scale(1.07);
      display: block;
    }
  }

  @media (max-width: 480px) {
    .spinner-wheel-table .__wheel {
      transform: translateX(-50%) translateX(50vw) scale(0.65) translateY(-110px);
    }

    .spinner-wheel-table.__entries-box .entries-box {
      transform: translateY(-150px);
    }
  }
}
</style>
